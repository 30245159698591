<template>
    <v-container>
        <myRecognitionsList />
        <nextPostBtn :url-next-post="nextPost" />
    </v-container>
</template>

<script>

import myRecognitionsList from '@/components/recognitions/myRecognitionsList'
import postMixin from '@/mixins/postMixin'
import nextPostBtn from '@/components/posts/nextPostBtn'
export default {
    name: 'MyRecognitionsView',
    components: { myRecognitionsList, nextPostBtn },
    mixins: [postMixin]
}
</script>

<style scoped>

</style>
