<template>
    <v-container
        fluid
        class="pt-0 mx-0 px-0"
    >
        <!--      Add purpose-->
        <div
            class="my-0 mx-0 px-0"
            style="position: relative"
        >
            <div
                class="title-post mr-16"
            >
                <span class="my-0 py-0">
                    {{ $t('recognitions.myRecognitions') }}
                </span>
            </div>

            <v-tooltip
                v-if="isOpenRecognition"
                top
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-fab-transition>
                        <v-btn
                            v-show="true"
                            color="secondary mt-2"
                            dark
                            absolute
                            top
                            right
                            fab
                            style="right: 0px;"
                            v-bind="attrs"
                            @click="createPurpose"
                            v-on="on"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </template>

                <span>{{ $t('recognitions.newRecognitionToolTip') }}</span>
            </v-tooltip>

            <v-row>
                <v-col class="px-3">
                    <!--            <v-btn
                class="mx-2 my-2"
                fab
                dark
                color="secondary"
                @click="createPurpose"
            >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>-->

                    <!--      Purposes List-->
                    <!--      :search="search"-->
                    <v-data-iterator
                        :items="dataFilter"
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        :sort-by="sortBy.toLowerCase()"
                        :sort-desc="sortDesc"
                        hide-default-footer
                    >
                        <!--                        <template v-slot:header>
                            <v-toolbar
                                v-if="$vuetify.breakpoint.mdAndUp"
                                dark
                                color="primary"
                                class="mb-1 mt-3 rounded-lg"
                            >
                                <v-text-field
                                    v-model="search"
                                    clearable
                                    flat
                                    solo-inverted
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    :label="$t('common.search')"
                                />

                                <template>-->
                        <!--                        <v-spacer />
                        <v-select
                            v-model="sortBy"
                            flat
                            solo-inverted
                            hide-details
                            :items="keys"
                            prepend-inner-icon="mdi-magnify"
                            label="Sort by"
                        />-->
                        <!--                                    <v-spacer />
                                    <v-btn-toggle
                                        v-model="sortDesc"
                                        mandatory
                                        class="ml-2"
                                    >
                                        <v-btn
                                            small
                                            depressed
                                            color="primary"
                                            :value="false"
                                        >
                                            <v-icon>mdi-arrow-up</v-icon>
                                        </v-btn>
                                        <v-btn
                                            small
                                            depressed
                                            color="primary"
                                            :value="true"
                                        >
                                            <v-icon>mdi-arrow-down</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                </template>
                            </v-toolbar>
                        </template>-->

                        <template v-slot:default="props">
                            <v-row>
                                <v-col
                                    v-for="item in props.items"
                                    :key="item.id"
                                    cols="12"
                                >
                                    <recognitionSingle
                                        :item="item"
                                        :is-only-view="!isOpenRecognition"
                                        @update:recognitions="fetchPurpose"
                                    />
                                </v-col>
                            </v-row>
                        </template>

                        <template v-slot:no-data>
                            <div class="d-flex flex-column align-center text-gray-600 mt-6">
                                <span class="text-h6">
                                    {{ $t('recognitions.noDataTitle') }}
                                </span>

                                <div class="d-flex flex-column align-center text-subtitle-1">
                                    <div v-html="$t('recognitions.noDataInfo')" />
                                </div>
                            </div>
                        </template>
                        <template
                            v-if="items && items.length"
                            v-slot:footer
                        >
                            <v-row
                                class="mt-2 ml-0 mr-0"
                                align="center"
                                justify="center"
                            >
                                <span class="grey--text">{{ $t('common.itemsPerPage') }}</span>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            dark
                                            text
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ itemsPerPage }}
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(number, index) in itemsPerPageArray"
                                            :key="index"
                                            @click="updateItemsPerPage(number)"
                                        >
                                            <v-list-item-title>{{ number }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-spacer />

                                <span
                                    class="grey--text"
                                >
                                    {{ $t('common.page') + " " + page + " " + $t('common.of') + " " + numberOfPages }}
                                </span>
                                <div>
                                    <v-btn
                                        fab
                                        dark
                                        color="primary"
                                        class="ml-4 mr-1"
                                        @click="formerPage"
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        dark
                                        color="primary"
                                        class="ml-1 mr-4"
                                        @click="nextPage"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-col>
            </v-row>
        </div>

        <v-row class="text-gray-600">
            <v-col>
                <div
                    class="my-3"
                    style="display: flex; "
                >
                    <span
                        style="cursor: pointer"
                        @click="overlayVideo = true"
                    > {{ $t('recognitions.help') }}</span>

                    <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        outlined
                        @click="overlayVideo = true"
                    >
                        <v-icon dark>
                            mdi-information-variant
                        </v-icon>
                    </v-btn>
                </div>

                <span
                    class="text-h6"
                    v-html="$t('purposes.help.title')"
                />

                <div
                    style="display: grid; grid-template-columns: 60px auto; row-gap: 15px; column-gap: 5px; align-items: center"
                >
                    <img
                        src="@/assets/img/purposes/recognition-create-help.png"
                        style="max-height: 40px"
                    >
                    <span v-html="$t('recognitions.myRecognitionsHelp.add')" />

                    <div style="display: flex; background-color: #28344a; border-radius: 10%; margin: 5px 3px">
                        <v-icon
                            class="mr-2"
                            dark
                        >
                            mdi-file-document-edit
                        </v-icon>

                        <v-icon
                            dark
                        >
                            mdi-delete-forever
                        </v-icon>
                    </div>
                    <span v-html="$t('recognitions.myRecognitionsHelp.editDelete')" />
                </div>
            </v-col>
        </v-row>

        <confirm-dialog
            ref="confirm"
        />

        <overlay-full-screen-video
            :overlay="overlayVideo"
            poster="https://back-itpaerovaluesawards.com/videos/mov_bbb-poster.jpeg"
            @update:overlay="overlayVideo = $event"
        >
            <source
                :src="`https://back-itpaerovaluesawards.com/videos/paso4_reconocimiento_${getLang}.mp4`"
                type="video/mp4"
            >
        </overlay-full-screen-video>
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import recognitionSingle from '@/components/recognitions/recognitionSingle'

import OverlayFullScreenVideo from "@/components/common/OverlayFullScreenVideo";

import { mapGetters } from 'vuex'
export default {
    name: 'MyRecognitionsList',
    components: { ConfirmDialog, recognitionSingle, OverlayFullScreenVideo },
    data() {
        return {
            urlMain: '/purposes?include=recognized_user',
            items: [], // purposes
            itemSelected: null,
            itemsPerPageArray: [2, 4, 8],
            search: '',
            filter: {},
            sortDesc: true,
            page: 1,
            itemsPerPage: 4,
            sortBy: 'attributes.updated-at',
            purposeCloud: null,
            overlayVideo: false,
            isOpenRecognition: false
        }
    },
    computed: {
        ...mapGetters("languages", ['getLang']),
        ...mapGetters(['getMyGroups']),
        dataFilter() {
            const search = this.search;

            if (!search) return this.items;

            return this.items.filter(child => {
                const userDataFilter = (child?.userData?.attributes?.name ?? "") + " " + (child?.userData?.attributes?.lastname ?? "");

                const att = child.attributes;
                return (att.body && att.body.toUpperCase().includes(search.toUpperCase())) ||
                    (userDataFilter.toUpperCase()).includes(search.toUpperCase()) ||
                    (att['created-at'] && this.dateHelper.getDateTimeTableFormat(att['created-at']).includes(search)) ||
                    (att['updated-at'] && this.dateHelper.getDateTimeTableFormat(att['updated-at']).includes(search));
            }
            );
        },
        numberOfPages() {
            return Math.ceil(this.items.length / this.itemsPerPage)
        }
        /* filteredKeys() {
            return this.keys.filter(key => key !== 'Name')
        } */
    },
    async mounted() {
        this.fetchPurpose();

        this.overlayVideo = !await this.$store.dispatch('cache/getAndUpdatePersistentInfo', { key: 'recognition-create-video', value: true });

        this.isOpenRecognition = await this.$store.dispatch('configGeneral/isOpenRecognition');
    },
    methods: {

        async fetchPurpose() {
            try {
                this.$store.dispatch('loading/start');

                const purposes = await axios
                    .get(this.urlMain, {
                        params: {
                            'filter[my]': 1
                        }
                    });

                this.initialize(purposes.data);
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            } finally {
                this.$store.dispatch('loading/end')
            }
        },
        initialize($data) {
            // console.log(items)
            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0

            const recognitions = $data.data;
            const users = $data.included;

            this.items = recognitions.map(recognition => {
                recognition.userData = users.find(user => user.id == recognition.attributes.recognized_user_id);
                return recognition;
            });

            // console.log('this.items ', this.items)
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number
        },

        createPurpose() {
            this.$router.push({ name: 'RecognitionCreate' });
        }
    }
}
</script>

<!--<style >
.ql-align-right{
  text-align: right !important;
}
</style>-->
